<template>
    <div class="page-factory-description">
        <Collapse v-model="activeNames" @change="handleChange">
            <CollapseItem title="扫码质检" name="1" v-if="type == 2">
                <div class="content-text">1.点击上方的“扫码”按钮，对准设备上的二维码；</div>
                <div class="content-text">2.扫描成功后，进入质检流程；</div>
                <div class="content-text">3.质检通过后，确认并勾选信号和气泵的两个检查项；</div>
                <div class="content-text">4.点击“质检通过”，即代表该设备已通过出厂质检。</div>
            </CollapseItem>
            <CollapseItem title="返厂定损" :name="type">
                <div>1.点击“返厂定损”按钮，进入故障设备列表；</div>
                <div>2.通过输入设备编号或扫码进入返厂详情；</div>
                <div>3.对设备进行定损后，在详情内点击“定损开始质检”，填写质检单后并“提交”，待览宋确认</div>
            </CollapseItem>
        </Collapse>
    </div>
</template>

<script>
import { Collapse, CollapseItem } from 'vant'
export default {
    name: "FactoryDescription",
    data() {
        const vm = this
        return {
            activeNames: ['1'],
            type: vm.$route.query.type
        };
    },
    components: {
        Collapse,
        CollapseItem
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        let name = vm.$localStorage.getItem(USER_NAME);
        if (!name) {
            vm.$router.replace({
                path: "/factory/login"
            });
            return;
        }

    },
    methods: {
        handleChange() {

        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-factory-description {
    width: 100%;
    height: 100%;
    background: @blank;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    padding: 20px 10px;
    font-size: 0.16rem;

    .van-collapse-item {
        box-shadow: 0px 2px 10px 0px #D4D4D4;
        margin-bottom: 20px;
        padding: 20px 10px;
    }

    .van-collapse-item__content {
        font-size: 0.16rem;
        color: #494949;
    }
    .van-cell__title {
        font-size: 0.2rem;
    }
}
</style>
